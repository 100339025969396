import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import {
    dataCollectionRef,
    transactionsCollectionRef,
} from "../../firebase/fire-app";
import { currencyFormatter, numberWithCommas, timeStampToDate } from "../../utilities";

const ViewAllTransactions = () => {
    const [transactionType, setTransactionType] = useState("out");
    const [viewTransactions, setViewTransactions] = useState(null);

    const [allTransaction, loading, error] = useCollectionData(
        transactionsCollectionRef.orderBy("date", "desc"),
        { idField: "id" }
    );
    const [balanceData, balLoad, balErr] = useDocumentData(
        dataCollectionRef.doc("balance")
    );

    useEffect(() => {
        if (allTransaction && allTransaction.length > 0) {
            if (transactionType === "all") {
                setViewTransactions([...allTransaction]);
            } else if (transactionType === "out") {
                const filteredList = allTransaction.filter(
                    (item) => item.flow === "out"
                );
                setViewTransactions([...filteredList]);
            } else {
                const filteredList = allTransaction.filter(
                    (item) => item.flow === "in"
                );
                setViewTransactions([...filteredList]);
            }
        }
    }, [allTransaction, transactionType]);
    return (
        <>
            <SidebarComponent>
                <section>
                    <div className="container">
                        <div className="p-0 p-mb-5">
                            <div className="text-center">
                                <div
                                    className="btn-group mb-3"
                                    role="group"
                                    aria-label="Basic example"
                                >
                                    <button
                                        onClick={() =>
                                            setTransactionType("out")
                                        }
                                        className={`btn btn-smaller ${
                                            transactionType === "out"
                                                ? "btn-danger"
                                                : "btn-outline-danger"
                                        }`}
                                    >
                                        Debit
                                    </button>
                                    <button
                                        onClick={() => setTransactionType("in")}
                                        className={`btn btn-smaller ${
                                            transactionType === "in"
                                                ? "btn-primary"
                                                : "btn-outline-primary"
                                        }`}
                                    >
                                        Credit
                                    </button>
                                    <button
                                        onClick={() =>
                                            setTransactionType("all")
                                        }
                                        className={`btn btn-smaller ${
                                            transactionType === "all"
                                                ? "btn-secondary"
                                                : "btn-outline-secondary"
                                        }`}
                                    >
                                        All
                                    </button>
                                </div>
                            <p className="font-14 text-center text-italic">Monthly limit left: {numberWithCommas(balanceData?.MonthlyLimit)}</p>
                            </div>
                            {viewTransactions &&
                                viewTransactions.length > 0 &&
                                viewTransactions.map((item) => (
                                    <TransactionComponent
                                        data={item}
                                        key={item.id}
                                    />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewAllTransactions;

const TransactionComponent = ({ data }) => {
    return (
        <>
            {data.flow === "out" ? (
                <div className="card trx-card bg-light-red p-3">
                    <div className="d-flex justify-content-between">
                        <p>
                            Amount:{" "}
                            <span className="font-bold">
                                {currencyFormatter.format(data.amount)}
                            </span>
                        </p>
                        <p>Type: {data.spendType}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>{timeStampToDate(data.date)}</p>
                        <p>Note: {data.note}</p>
                    </div>
                </div>
            ) : (
                <div className="card trx-card bg-light-green p-3">
                    {data.amount}
                </div>
            )}
        </>
    );
};
