import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Components/DashboardLayout/DashboardLayout";
import "./style.scss";
import { Link } from "react-router-dom";
import dashboardMenu from "./DataDashboardMenu";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useAuth } from "../../Hooks/useAuth";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { dataCollectionRef } from "../../firebase/fire-app";
import SectionLoading from "../../Components/SectionLoading";
import { numberWithCommas } from "../../utilities";

const ViewDashboard = () => {
    const [data, loading, error] = useDocumentData(
        dataCollectionRef.doc("balance")
    );
    const [totalDebt, setTotalDebt] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [totalWorth, setTotalWorth] = useState(0);

    useEffect(() => {
        if (data) {
            const totalDebt = data.AmexCredit + data.VisaCredit;
            const subTotal =
                data.SCB +
                data.CityBank +
                data.BankAsia +
                data.bKash +
                data.Rocket +
                data.Nagad +
                data.Cash;

            const totalWorth = subTotal - totalDebt;

            setTotalDebt(totalDebt)
            setSubTotal(subTotal)
            setTotalWorth(totalWorth)
        }
    }, [data]);
    return (
        <>
            <SidebarComponent>
                <section>
                    <div className="container">
                        <div className="p-0 p-mb-5">
                            <h4 className="text-center mb-3 font-bold">
                                Balance Summary
                            </h4>
                            {data && (
                                <table class="table text-center table-dark table-sm">
                                    <thead>
                                        <tr>
                                            <td className="text-center">#</td>
                                            <th>Account</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">1</td>
                                            <td>SCB</td>
                                            <td>
                                                {numberWithCommas(data.SCB)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">2</td>
                                            <td>CityBank</td>
                                            <td>
                                                {numberWithCommas(
                                                    data.CityBank
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">3</td>
                                            <td>BankAsia</td>
                                            <td>
                                                {numberWithCommas(
                                                    data.BankAsia
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">4</td>
                                            <td>bKash</td>
                                            <td>
                                                {numberWithCommas(data.bKash)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">5</td>
                                            <td>Rocket</td>
                                            <td>
                                                {numberWithCommas(data.Rocket)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">6</td>
                                            <td>Nagad</td>
                                            <td>
                                                {numberWithCommas(data.Nagad)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">7</td>
                                            <td>Cash</td>
                                            <td>
                                                {numberWithCommas(data.Cash)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center bg-secondary">
                                                --
                                            </td>
                                            <td className="bg-secondary">
                                                Sub total
                                            </td>
                                            <td className="bg-secondary">
                                                {numberWithCommas(subTotal)}
                                            </td>
                                        </tr>
                                        <tr className="bg-danger">
                                            <td className="bg-danger text-center">
                                                7
                                            </td>
                                            <td className="bg-danger">
                                                AmexCredit
                                            </td>
                                            <td className="bg-danger">
                                                {numberWithCommas(
                                                    data.AmexCredit
                                                )}
                                            </td>
                                        </tr>
                                        <tr className="bg-danger">
                                            <td className="bg-danger text-center">
                                                7
                                            </td>
                                            <td className="bg-danger">
                                                VisaCredit
                                            </td>
                                            <td className="bg-danger">
                                                {numberWithCommas(
                                                    data.VisaCredit
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center bg-secondary">
                                                --
                                            </td>
                                            <td className="bg-secondary">
                                                Total Debt
                                            </td>
                                            <td className="bg-">
                                                {numberWithCommas(totalDebt)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center bg-secondary">
                                                --
                                            </td>
                                            <td className="bg-secondary">
                                                Total Worth
                                            </td>
                                            <td className="bg-">
                                                {numberWithCommas(totalWorth)}
                                            </td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td className="bg-light text-dark"></td>
                                            <td className="bg-light text-dark">
                                                Savings
                                            </td>
                                            <td className="bg-light text-dark">
                                                {numberWithCommas(data.saving)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewDashboard;
